import React from 'react';
import './App.css';
import videoBg from './assets/video/galexy.mp4';
import sivanLogo from './assets/images/sivan-fm.png';
import Radio from './Radio';

const App = () => {
  return (
    <div className="App">
      <section class="showcase">
        <div class="video-container">
          <video playsInline autoPlay muted loop>
            <source src={videoBg} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div class="content">
          <div className="logo">
            <img src={sivanLogo} alt="Sivan FM" />
          </div>
          <Radio />
          <div className="footer">
            <div className="contact-details">
              <h3>Contact Details</h3>
              <div className="left-section">
                <p><i className="fa fa-map-marker" /><a href="https://www.google.com/search?q=arul+migu+sivan+kovil+zurich+map&sxsrf=ALiCzsb4lT-QUv3PXSM5ozKHScsV1m_cOA%3A1665735106017&ei=whlJY5RQosXctQ-q7rDwBQ&ved=0ahUKEwiUuaLqot_6AhWiIrcAHSo3DF4Q4dUDCA4&uact=5&oq=arul+migu+sivan+kovil+zurich+map&gs_lcp=Cgdnd3Mtd2l6EAMyBwghEKABEAoyBwghEKABEAoyBwghEKABEAo6CggAEEcQ1gQQsAM6DQgAEEcQ1gQQsAMQyQM6CAgAEJIDELADSgQIQRgASgQIRhgAUOIHWK4MYPEOaAFwAXgAgAHAAYgBmQSSAQMwLjOYAQCgAQHIAQnAAQE&sclient=gws-wiz#" >Arulmigu Shivan Kovil. Industriestrasse 34 CH-8152 Glattbrugg Switzerland.</a></p>
              </div>
              <div className="left-section">
                <a href="tel:+41 (0) 44 371 02 42" className="link"><i className="fa fa-phone" /> +41 (0) 44 371 02 42</a>
                <a href="mailto:info@sivankovil.ch" className="link"><i className="fa fa-envelope" /> info@sivankovil.ch</a>
              </div>
            </div>
            <div className="copyright">
              <p><a href="https://www.sivankovil.ch/" target="_blank">சிவன் வானொலி</a> © {(new Date().getFullYear())} . All rights reserved.</p>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
}

export default App;